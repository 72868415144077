import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Link, Button, Card, CardHeader, CardBody, CardFooter, Tabs, Tab, Divider } from "@nextui-org/react";
import {
    Navbar,
    NavbarBrand,
    NavbarContent,
    NavbarItem,
    NavbarMenuToggle,
    NavbarMenu,
    NavbarMenuItem
} from "@nextui-org/react";

import { useAuth } from "../components/context/auth_context";
import { Sidebar } from "../components/sidebar";
import { ApiKeyCheck } from "../components/playground/api_key_check";
import { Endpoints } from "../components/playground/endpoints";
import { MethodButtons } from "../components/playground/method_buttons";
import { apiEndpointInfo, userModel, listModel, cycloneModel } from "../components/constants/api_endpoint_info";


export function Playground() {
    const navigate = useNavigate();
    const {
        address,
        truncateAddress,
        truncateTelegram,
        signedIn,
        signedInLoading,
        isVerifyingLoading,
        apiAccountData,
        handleSignIn,
        handleSignOut,
        handleOpen,
        handleDisconnect,
        createNewApiAccount,
        createNewApiAccountLoading,
        signUpTelegramUserData,
        setSignUpTelegramUserData,
        playgroundApiKey, setPlaygroundApiKey, playgroundApiKeyValid, checkPlaygroundApiKey, resetPlaygroundApiKey
    } = useAuth()

    const [selectedTab, setSelectedTab] = useState(apiEndpointInfo[0].endpoint);

    const activeCategory = apiEndpointInfo.find(category => category.endpoint === selectedTab) || {};
    const buttonsData = activeCategory.routes || [];

    const modelMapping = {
        user: userModel,
        list: listModel,
        cyclone: cycloneModel,
    };

    const handleButtonClick = (routeIndex) => {
        document.getElementById(`endpoint-${routeIndex}`)?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <Sidebar>
                <Navbar isBordered maxWidth="full">
                    <p className="text-xs text-default-500">Jump to Endpoint</p>
                    <NavbarContent className="flex flex-row overflow-x-auto gap-2">
                        {buttonsData.map((route, routeIndex) => (
                            <Button className="whitespace-nowrap min-w-max" key={routeIndex} size="sm" onClick={() => handleButtonClick(routeIndex)}>
                                {route.name}
                            </Button>
                        ))}
                    </NavbarContent>
                </Navbar>

                {!signedIn && !playgroundApiKeyValid && (
                    // <Card className="bg-yellow-500 text-black" radius="none">
                    //     <CardHeader className=" flex flex-col justify-start items-start ">
                    //         <span className="font-bold">
                    //             Sign in with your API account wallet or enter a valid API key
                    //         </span>

                    //         <p>
                    //             Don't have an API key? Check out the <Link href="/pricing">pricing</Link> page where you can create a trial account or buy a credit/subscription package.
                    //         </p>
                    //     </CardHeader>
                    // </Card>

                    <Card className="bg-yellow-500 text-black" radius="none">
                        <CardHeader className=" flex flex-col justify-start items-start ">
                            <span className="font-bold">
                                Important!
                            </span>

                            <p className="text-sm">
                                Sign in with your API account wallet or enter a valid API key
                            </p>

                            <p className="text-sm">
                                Don't have an API key? Check out the <Link className="text-sm" href="/pricing">pricing</Link> page where you can create a trial account or buy a credit/subscription package.
                            </p>
                        </CardHeader>
                    </Card>
                )}

                {!signedIn && <ApiKeyCheck />}

                <div className="p-6">
                    <div className={(!signedIn && !playgroundApiKeyValid) ? "mt-4" : ""}>
                        <Tabs
                            aria-label="API Options"
                            selectedKey={selectedTab}
                            onSelectionChange={setSelectedTab}
                        >
                            {apiEndpointInfo.map((category) => (
                                <Tab key={category.endpoint} title={category.endpoint.charAt(0).toUpperCase() + category.endpoint.slice(1)}>
                                    <div className="space-y-4">
                                        <p className="text-md font-bold">{category.description}</p>

                                        {category.routes.map((route, routeIndex) => (
                                            <Endpoints
                                                _key={route.name}
                                                _id={`endpoint-${routeIndex}`}
                                                route={selectedTab}
                                                name={route.name}
                                                method={route.method}
                                                disabled={route.disabled}
                                                popular={route.popular}
                                                creditValue={route.creditValue}
                                                free={route.free}
                                                url={route.url}
                                                description={route.description}
                                                model={modelMapping[route.model]}
                                                modelName={route.model}
                                            />
                                        ))}
                                    </div>
                                </Tab>
                            ))}
                        </Tabs>
                    </div>
                </div>

                {/* <div className={`relative ${!signedIn ? "mt-56" : "mt-28"}`}>
                    <div className="fixed inset-x-0 bottom-0 z-50">
                        <MethodButtons buttonsData={buttonsData} />
                        {!signedIn && <ApiKeyCheck />}
                    </div>
                </div> */}
            </Sidebar>
        </>
    );
}