import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
// import { Link } from 'react-router-dom';
// import {Link} from "@nextui-org/react";
import { Card, CardHeader, CardBody, Button, RadioGroup, Radio, Input, DatePicker, ScrollShadow, Spinner, Tooltip, Tabs, Tab, Snippet } from "@nextui-org/react";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure
} from "@nextui-org/react";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";


import { PiBracketsCurlyBold } from "react-icons/pi";
import { IoMdDownload } from "react-icons/io";
// import { Download } from 'lucide-react';
import { FaPython } from "react-icons/fa";
import { SiCurl } from "react-icons/si";
import { IoLogoJavascript } from "react-icons/io5";

import { useAuth } from "../context/auth_context";

export function Endpoints({ _key, _id, route, name, method, disabled, popular, creditValue, free, url, description, model, modelName }) {
    const { apiAccountData, signedIn, playgroundApiKey, playgroundApiKeyValid } = useAuth();

    // const [selectedUser, setSelectedUser] = useState('oz_dao');
    // const [selectedHow, setSelectedHow] = useState("username");
    // const [selectedPage, setSelectedPage] = useState(1);
    // const [selectedSince, setSelectedSince] = useState(null)
    // const [loading, setLoading] = useState(false)
    // const [data, setData] = useState(null)

    const [formValues, setFormValues] = useState(() =>
        Object.keys(model).reduce((acc, key) => {
            acc[key] = model[key].default;
            return acc;
        }, {})
    );
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const leftCardRef = useRef(null);
    const rightCardRef = useRef(null);

    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [scrollBehavior, setScrollBehavior] = React.useState("inside");

    useLayoutEffect(() => {
        if (leftCardRef.current && rightCardRef.current) {
            const leftHeight = leftCardRef.current.offsetHeight;
            rightCardRef.current.style.maxHeight = `${leftHeight}px`;
            rightCardRef.current.style.overflow = 'auto';
        }
    }, [data]);

    const handleInputChange = (field, value) => {
        setFormValues((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const validateForm = (formValues) => {
        for (const key in model) {
            if (model[key].required && !formValues[key]) {
                console.error(`${key} field is required.`);
                return false;
            }
        }
        return true;
    };

    const applyModelDefaults = (model, formValues) => {
        const preparedValues = {};
        Object.keys(model).forEach((key) => {
            preparedValues[key] =
                formValues[key] !== undefined && formValues[key] !== ""
                    ? formValues[key]
                    : model[key].default;
        });
        return preparedValues;
    };

    const fetchData = () => {
        // Apply defaults using the active model
        const preparedFormValues = applyModelDefaults(model, formValues);

        if (!validateForm(preparedFormValues)) {
            return;
        }

        setLoading(true);
        fetch(`${window.origin}/api/proxy_post`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                model: modelName,
                ...preparedFormValues,
                api_key: signedIn ? apiAccountData.api_key : playgroundApiKey,
                url,
            }),
        })
            .then((res) => res.json())
            .then((result) => {
                setData(result);
                setLoading(false);
            })
            .catch(() => {
                setData(null);
                setLoading(false);
            });
    };

    const downloadData = (format) => {
        const blob = new Blob(
            [format === "json" ? JSON.stringify(data) : convertToCSV(data)],
            {
                type: format === "json" ? "application/json" : "text/csv",
            }
        );
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${name}_data.${format}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const convertToCSV = (data) => {
        if (!data || !Array.isArray(data.data?.data)) {
            console.error("Invalid data format for CSV conversion.");
            return "";
        }

        const nestedData = data.data.data; // Adjust based on your actual data structure
        const headers = Object.keys(nestedData[0]); // Extract headers from the first item
        const rows = nestedData.map((row) =>
            headers
                .map((key) => {
                    const value = row[key];
                    // Handle quotes and escape them if necessary
                    if (typeof value === "string") {
                        return `"${value.replace(/"/g, '""')}"`;
                    }
                    return value ?? ""; // Handle null/undefined values
                })
                .join(",")
        );

        return [headers.join(","), ...rows].join("\n");
    };

    // const formatSinceDate = (date) => {
    //     if (!date) return null;
    //     const { year, month, day } = date;
    //     return new Date(year, month - 1, day).toISOString();
    // };

    // const fetchData = () => {
    //     const formattedSince = selectedSince ? formatSinceDate(selectedSince) : null;

    //     setLoading(true)

    //     fetch(`${window.origin}/api/proxy_post`, {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({
    //             api_key: signedIn ? apiAccountData.api_key : playgroundApiKey,
    //             url: url,
    //             user: selectedUser,
    //             how: selectedHow,
    //             page: selectedPage,
    //             since: formattedSince
    //         })
    //     })
    //         .then((response) => {
    //             if (!response.ok) {
    //                 throw new Error("Network response was not ok");
    //             }
    //             return response.json();
    //         })
    //         .then((data) => {
    //             console.log(data)

    //             setData(data)
    //             setLoading(false)
    //         })
    //         .catch((error) => {
    //             console.log("Fetch error: ", error);
    //             setData(false)
    //             setLoading(false)
    //         });
    // }

    // const triggerDownload = (blob, filename) => {
    //     const link = document.createElement('a');
    //     link.href = URL.createObjectURL(blob);
    //     link.download = filename;
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };

    // const downloadJSON = () => {
    //     // console.log(`route: ${route}`);
    //     // console.log(`name: ${name}`);
    //     // console.log(`Method: ${method}`);
    //     // console.log(`URL: ${url}`);

    //     const jsonData = JSON.stringify(data);
    //     const blob = new Blob([jsonData], { type: 'application/json' });
    //     let file_name = `${route}_${name}_${selectedUser}_page_${selectedPage}`
    //     triggerDownload(blob, `${file_name}.json`);
    // };

    // const convertToCSV = (data) => {
    //     const rows = [];

    //     // Get all unique keys from the data to create the header row
    //     const header = Object.keys(data[0]);
    //     rows.push(header);

    //     // Add the data rows dynamically, handling line breaks and quotes in text fields
    //     data.forEach(item => {
    //         const row = header.map(fieldName => {
    //             const value = item[fieldName];

    //             // Handle quotes and escape them if necessary
    //             if (typeof value === 'string') {
    //                 return `"${value.replace(/"/g, '""')}"`;
    //             }

    //             return value;
    //         });
    //         rows.push(row);
    //     });

    //     return rows.map(e => e.join(",")).join("\n");
    // };

    // const downloadCSV = () => {
    //     if (!data || !Array.isArray(data.data.data) || data.data.data.length === 0) {
    //         console.error("No valid data to download.");
    //         return;
    //     }

    //     const csvData = convertToCSV(data.data.data);
    //     const blob = new Blob([csvData], { type: 'text/csv' });
    //     let file_name = `${route}_${name}_${selectedUser}_page_${selectedPage}`
    //     triggerDownload(blob, `${file_name}.csv`);
    // };

    return (
        <>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <Card id={_id} ref={leftCardRef}>
                    <CardHeader className="flex flex-col justify-start items-start overflow-x-auto">
                        <div className="flex flex-row w-full justify-between">
                            <div>
                                <p className={`${disabled ? 'line-through' : ''}`}>{name} {free ? <span className="text-lg">🆓</span> : null} {popular ? <span className="text-lg">🔥🔥🔥</span> : null}</p>
                                <p className="text-default-500 text-xs">{description}</p>
                            </div>

                            <Tooltip content="API Request Examples">
                                <Button onClick={onOpen} size="sm" isIconOnly><PiBracketsCurlyBold /></Button>
                            </Tooltip>
                        </div>

                        {/* <p className="text-default-500 text-xs">Cost: {creditValue === 0 && name !== 'get_userid' ? 'Route Not Available' : (name === 'get_userid' ? <span className="text-green-500">FREE</span> : `${creditValue} ${creditValue === 1 ? 'credit' : 'credits'}`)}</p> */}
                        <p className="text-default-500 text-xs">
                            {creditValue === 0
                                ? 'Route Not Available'
                                : `${creditValue} ${creditValue === 1 ? 'credit' : 'credits'}`}
                        </p>
                        <p className="text-default-500 text-xs mt-4"><span className="text-xs font-bold">{method}</span> {url}</p>
                    </CardHeader>
                    <CardBody>
                        <div className="space-y-4">
                            {Object.keys(model).map((key) => (
                                <div key={key}>
                                    {/* <p>{model[key]?.description}</p> */}
                                    {/* <p className="">{key} <span className="text-xs text-default-500">{model[key]?.type}</span> <span className={`text-xs ${model[key]?.required ? "text-red-500" : "text-default-500"}`}>{model[key]?.required ? "Required" : null}</span></p> */}
                                    <p className="">
                                        {key.charAt(0).toUpperCase() + key.slice(1)}{" "}
                                        <span className="text-xs text-default-500">
                                            {model[key]?.type
                                                ? model[key]?.type.charAt(0).toUpperCase() + model[key]?.type.slice(1)
                                                : ""}
                                        </span>{" "}
                                        <span
                                            className={`text-xs ${model[key]?.required ? "text-red-500" : "text-default-500"
                                                }`}
                                        >
                                            {model[key]?.required ? "Required" : null}
                                        </span>
                                    </p>
                                    <p className="text-default-500 text-xs mb-1">{model[key]?.description}</p>
                                    <Input
                                        type={model[key]?.type || "text"}
                                        value={formValues[key]}
                                        onChange={(e) => handleInputChange(key, e.target.value)}
                                    />
                                </div>
                            ))}

                            <div>
                                {disabled ? (
                                    <Button size="sm" isDisabled>Route Not Available</Button>
                                ) : (
                                    playgroundApiKeyValid || signedIn ? (
                                        <Button size="sm" onClick={fetchData}>Execute</Button>
                                    ) : (
                                        <Button size="sm" isDisabled>Execute</Button>
                                    )
                                )}
                            </div>
                        </div>
                    </CardBody>
                </Card>

                <Card ref={rightCardRef}>
                    {data && (
                        <CardHeader className="flex justify-end">
                            <Dropdown>
                                <DropdownTrigger>
                                    <Button
                                        variant="solid"
                                    >
                                        Export <IoMdDownload />
                                    </Button>
                                </DropdownTrigger>
                                <DropdownMenu aria-label="Static Actions">
                                    {/* <DropdownItem key="csv" onClick={downloadCSV}>CSV</DropdownItem>
                                    <DropdownItem key="json" onClick={downloadJSON}>JSON</DropdownItem> */}
                                    <DropdownItem key="json" onClick={() => downloadData("json")}>
                                        JSON
                                    </DropdownItem>
                                    <DropdownItem key="csv" onClick={() => downloadData("csv")}>
                                        CSV
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </CardHeader>
                    )}
                    <CardBody>
                        <ScrollShadow>
                            {loading ? (
                                <Spinner />
                            ) : (
                                <pre className="text-default-500">
                                    {data ? JSON.stringify(data, null, 2) : '...'}
                                </pre>
                            )}
                        </ScrollShadow>
                    </CardBody>
                </Card>
            </div>

            <Modal isOpen={isOpen} onOpenChange={onOpenChange} scrollBehavior={scrollBehavior} backdrop="blur">
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">API Request Examples</ModalHeader>
                            <ModalBody>
                                <Tabs aria-label="Options">
                                    <Tab
                                        key="curl"
                                        title={
                                            <div className="flex items-center space-x-2">
                                                <SiCurl />
                                                <span>cURL</span>
                                            </div>
                                        }
                                    >
                                        <Snippet className="w-full" symbol="">
                                            <pre className="whitespace-pre-wrap break-words break-all text-xs text-default-500">
                                                {`curl -X POST ${url} \\`}
                                                {`\n-H "accept: application/json" \\`}
                                                {/* {`\n-H "x-api-key: ${signedIn ? apiAccountData.api_key : playgroundApiKey}" \\`} */}
                                                {`\n-H "x-api-key: ${signedIn ? apiAccountData.api_key : (playgroundApiKey ? playgroundApiKey : 'API-KEY-HERE')}" \\`}
                                                {`\n-H "Content-Type: application/json" \\`}
                                                {`\n-d '${JSON.stringify(
                                                    Object.fromEntries(
                                                        Object.entries(model).map(([key, value]) => [
                                                            key,
                                                            value.default ?? null,
                                                        ])
                                                    ),
                                                    null,
                                                    2
                                                )}'`}
                                            </pre>
                                        </Snippet>
                                    </Tab>
                                    <Tab
                                        key="python"
                                        title={
                                            <div className="flex items-center space-x-2">
                                                <FaPython />
                                                <span>Python</span>
                                            </div>
                                        }
                                    >
                                        <Snippet className="w-full" symbol="">
                                            <pre className="whitespace-pre-wrap break-words break-all text-xs text-default-500">
                                                {`import requests\n\n`}
                                                {`url = "${url}"\n`}
                                                {`headers = {\n`}
                                                {`  "accept": "application/json",\n`}
                                                {/* {`  "x-api-key": "${signedIn ? apiAccountData.api_key : playgroundApiKey}",\n`} */}
                                                {`  "x-api-key": "${signedIn ? apiAccountData.api_key : (playgroundApiKey ? playgroundApiKey : 'API-KEY-HERE')}",\n`}
                                                {`  "Content-Type": "application/json"\n`}
                                                {`}\n`}
                                                {`payload = ${JSON.stringify(
                                                    Object.fromEntries(
                                                        Object.entries(model).map(([key, value]) => [
                                                            key,
                                                            value.default ?? null,
                                                        ])
                                                    ),
                                                    null,
                                                    2
                                                ).replace(/"null"/g, 'None')}\n`}
                                                {`response = requests.post(url, headers=headers, json=payload)\n`}
                                                {`print(response.json())`}
                                            </pre>
                                        </Snippet>
                                    </Tab>
                                    <Tab
                                        key="js"
                                        title={
                                            <div className="flex items-center space-x-2">
                                                <IoLogoJavascript />
                                                <span>JavaScript</span>
                                            </div>
                                        }
                                    >
                                        <Snippet className="w-full" symbol="">
                                            <pre className="whitespace-pre-wrap break-words break-all text-xs text-default-500">
                                                {`const url = "${url}";\n`}
                                                {`const headers = {\n`}
                                                {`  "accept": "application/json",\n`}
                                                {/* {`  "x-api-key": "${signedIn ? apiAccountData.api_key : playgroundApiKey}",\n`} */}
                                                {`  "x-api-key": "${signedIn ? apiAccountData.api_key : (playgroundApiKey ? playgroundApiKey : 'API-KEY-HERE')}",\n`}
                                                {`  "Content-Type": "application/json"\n`}
                                                {`};\n`}
                                                {`const payload = ${JSON.stringify(
                                                    Object.fromEntries(
                                                        Object.entries(model).map(([key, value]) => [
                                                            key,
                                                            value.default ?? null,
                                                        ])
                                                    ),
                                                    null,
                                                    2
                                                )};\n\n`}
                                                {`fetch(url, {\n`}
                                                {`  method: 'POST',\n`}
                                                {`  headers: headers,\n`}
                                                {`  body: JSON.stringify(payload)\n`}
                                                {`})\n`}
                                                {`.then(response => response.json())\n`}
                                                {`.then(data => console.log(data))\n`}
                                                {`.catch(error => console.error('Error:', error));`}
                                            </pre>
                                        </Snippet>
                                    </Tab>
                                </Tabs>
                            </ModalBody>
                            {/* <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Close
                                </Button>
                                <Button color="primary" onPress={onClose}>
                                    Action
                                </Button>
                            </ModalFooter> */}
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}