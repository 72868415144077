export const apiEndpointInfo = [
    {
        endpoint: "metadata",
        description: "Handles user metadata including counts, tweets, and usernames.",
        routes: [
            { name: "get_follower_count", method: "POST", disabled: false, popular: false, creditValue: 2, free: false, url: 'https://toto.oz.xyz/api/metadata/get_follower_count', description: 'Get follower count of a user', model: "user", subscription: true },
            { name: "get_latest_metadata", method: "POST", disabled: false, popular: false, creditValue: 1, free: false, url: 'https://toto.oz.xyz/api/metadata/get_latest_metadata', description: 'Get most recent valid metadata for a given user', model: "user", subscription: true },
            { name: "get_metadata_history", method: "POST", disabled: false, popular: false, creditValue: 2, free: false, url: 'https://toto.oz.xyz/api/metadata/get_metadata_history', description: 'Get metadata history for a given user', model: "user", subscription: true },
            { name: "get_bio_history", method: "POST", disabled: false, popular: false, creditValue: 2, free: false, url: 'https://toto.oz.xyz/api/metadata/get_bio_history', description: 'Get bio history for a given user', model: "user", subscription: true },
            { name: "get_past_usernames", method: "POST", disabled: false, popular: true, creditValue: 3, free: false, url: 'https://toto.oz.xyz/api/metadata/get_past_usernames', description: 'Get all previous usernames for a given user', model: "user", subscription: true },
            { name: "get_tweets", method: "POST", disabled: false, popular: false, creditValue: 1, free: false, url: 'https://toto.oz.xyz/api/metadata/get_tweets', description: 'Get tweets for a given user', model: "user", subscription: true },
            { name: "get_userid", method: "POST", disabled: false, popular: false, creditValue: 1, free: false, url: 'https://toto.oz.xyz/api/metadata/get_userid', description: 'Get Twitter userid for a given user', model: "user", subscription: true },

            // active scraping
            { name: "get_latest_tweets", method: "POST", disabled: false, popular: false, creditValue: 6, free: false, url: 'https://toto.oz.xyz/api/metadata/get_latest_tweets', description: 'Get latest tweets of a user', model: "user", subscription: true },
            { name: "get_current_num_tweets", method: "POST", disabled: false, popular: false, creditValue: 6, free: false, url: 'https://toto.oz.xyz/api/metadata/get_current_num_tweets', description: 'Get the current number of tweets for a user.', model: "user", subscription: false },
            { name: "get_current_num_following", method: "POST", disabled: false, popular: false, creditValue: 6, free: false, url: 'https://toto.oz.xyz/api/metadata/get_current_num_following', description: 'Get the current following number for a user.', model: "user", subscription: false },
            { name: "get_current_num_followers", method: "POST", disabled: false, popular: false, creditValue: 6, free: false, url: 'https://toto.oz.xyz/api/metadata/get_current_num_followers', description: 'Get the current number of followers for a user.', model: "user", subscription: false },
            { name: "get_current_metadata", method: "POST", disabled: false, popular: false, creditValue: 6, free: false, url: 'https://toto.oz.xyz/api/metadata/get_current_metadata', description: 'Get the current metadata for a user.', model: "user", subscription: false },
        ]
    },
    {
        endpoint: "graph",
        description: "Provides data about social graphs like followers and following.",
        routes: [
            { name: "get_followers", method: "POST", disabled: false, popular: true, creditValue: 3, free: false, url: 'https://toto.oz.xyz/api/graph/get_followers', description: 'Get followers of a user', model: "user", subscription: true },
            { name: "get_first_followers", method: "POST", disabled: false, popular: true, creditValue: 3, free: false, url: 'https://toto.oz.xyz/api/graph/get_first_followers', description: 'Get up to the first 20 followers of a user', model: "user", subscription: true },
            { name: "get_following", method: "POST", disabled: false, popular: true, creditValue: 3, free: false, url: 'https://toto.oz.xyz/api/graph/get_following', description: 'Get following of a user', model: "user", subscription: true },
        ]
    },
    {
        endpoint: "lists",
        description: "Provides details pertaining to Twitter lists.",
        routes: [
            { name: "get_latest_list_tweets", method: "POST", disabled: true, popular: false, creditValue: 0, free: false, url: 'https://toto.oz.xyz/api/lists/get_latest_list_tweets', description: 'Get latest tweets from a list', model: "list", subscription: false },
            { name: "get_list_members", method: "POST", disabled: true, popular: false, creditValue: 0, free: false, url: 'https://toto.oz.xyz/api/lists/get_list_members', description: 'Get list members', model: "list", subscription: false },
        ]
    },
    {
        endpoint: "alpha",
        description: "Access to alpha features such as classification and historical scores.",
        routes: [
            { name: "get_classification", method: "POST", disabled: false, popular: false, creditValue: 1, free: false, url: 'https://toto.oz.xyz/api/alpha/get_classification', description: 'Get classifications for given username(s)', model: "user", subscription: true },
            { name: "get_score_history", method: "POST", disabled: false, popular: false, creditValue: 2, free: false, url: 'https://toto.oz.xyz/api/alpha/get_score_history', description: 'Get score history for a given user', model: "user", subscription: true },
        ]
    },
    {
        endpoint: "cyclone",
        description: "Provides unfiltered access to real-time data streams.",
        routes: [
            { name: "get_cyclone_tweets", method: "POST", disabled: false, popular: false, creditValue: 3, free: false, url: 'https://toto.oz.xyz/api/cyclone/get_cyclone_tweets', description: 'Get unfiltered real-time tweets', model: "cyclone", subscription: true },
        ]
    }
];

export const userModel = {
    user: {
        type: 'string',
        required: true,
        default: 'oz_dao',
        description: 'Username or userid',
    },
    how: {
        type: 'string',
        required: false,
        default: 'username',
        description: "Lookup method, can be 'username' or 'userid'",
    },
    page: {
        type: 'integer',
        required: false,
        default: 1,
        description: 'Pagination',
    },
};

export const listModel = {
    list_id: {
        type: 'string',
        required: true,
        default: null,
        description: 'List ID',
    },
    page: {
        type: 'integer',
        required: false,
        default: 1,
        description: 'Pagination',
    },
};

export const cycloneModel = {
    tweets_since: {
        type: 'integer',
        required: false,
        default: null,
        description: 'Filter tweets since this tweet_id',
    },
    users_since: {
        type: 'integer',
        required: false,
        default: null,
        description: 'Filter users since this userid',
    },
    num_rows: {
        type: 'integer',
        required: false,
        default: 100,
        maximum: 100,
        description: 'Number of rows to return',
    },
};

// export const creditPricing = [
//     {
//         credit_title: "10k",
//         credits: "10000",
//         img: "/pricing_cards/cc1.png",
//         // img: "/credit_pricing1.png",
//         // img: "/c1.png",
//         price_title: "$100",
//         price: "100",
//     },
//     {
//         credit_title: "50k",
//         credits: "50000",
//         img: "/pricing_cards/cc2.png",
//         // img: "/credit_pricing2.png",
//         // img: "/c2.png",
//         price_title: "$400",
//         price: "400",
//     },
//     {
//         credit_title: "100k",
//         credits: "100000",
//         img: "/pricing_cards/cc3.png",
//         // img: "/credit_pricing3.png",
//         // img: "/c3.png",
//         price_title: "$750",
//         price: "750",
//     }
// ]

export const creditPricing = [
    // starter credit packages
    {
        credit_title: "100",
        credits: "100",
        img: "/pricing_cards/cc3.png",
        price_title: "$10",
        price: "10",
    },
    {
        credit_title: "400",
        credits: "400",
        img: "/pricing_cards/cc2.png",
        price_title: "$20",
        price: "20",
    },
    {
        credit_title: "700",
        credits: "700",
        img: "/pricing_cards/cc1.png",
        price_title: "$30",
        price: "30",
    },


    // main tier credit packages
    {
        credit_title: "5k",
        credits: "5000",
        img: "/pricing_cards/cc1.png",
        // img: "/credit_pricing1.png",
        // img: "/c1.png",
        price_title: "$100",
        price: "100",
    },
    {
        credit_title: "25k",
        credits: "25000",
        img: "/pricing_cards/cc2.png",
        // img: "/credit_pricing2.png",
        // img: "/c2.png",
        price_title: "$400",
        price: "400",
    },
    {
        credit_title: "50k",
        credits: "50000",
        img: "/pricing_cards/cc3.png",
        // img: "/credit_pricing3.png",
        // img: "/c3.png",
        price_title: "$750",
        price: "750",
    },

    // custom credit packages
    {
        // update from 45k credits for $500
        credit_title: "100k",
        credits: "100000",
        price_title: "$900",
        price: "900",
        wallet_address: '0x60a510ab8eac18ef29f75592a14dfd446e6fd5ca' // lunarfang_416
        // wallet_address: '0xEbC502908df2d0AC5e84Cc20a0780bcb9456F8eC' // lunarfang_416
    },
    {
        credit_title: "150k",
        credits: "150000",
        // img: "/pricing_cards/cc1.png",
        // img: "/credit_pricing3.png",
        // img: "/c3.png",
        price_title: "$1700",
        price: "1700",
        wallet_address: '0xd1ca9a1d856afceff16a41dd03bbd55b0e3c90a3' // nbxxx
    },
    {
        credit_title: "100k",
        credits: "100000",
        // img: "/pricing_cards/cc1.png",
        // img: "/credit_pricing3.png",
        // img: "/c3.png",
        price_title: "$100k",
        price: "0",
        wallet_address: '0xEbC502908df2d0AC5e84Cc20a0780bcb9456F8eC' // babushka_slayer
    },
    {
        credit_title: "100k",
        credits: "100000",
        // img: "/pricing_cards/cc1.png",
        // img: "/credit_pricing3.png",
        // img: "/c3.png",
        price_title: "$100k",
        price: "0",
        wallet_address: '0x2b994612febf52565533545cc374d75f7652ad9d' // mar2194
    },
    {
        credit_title: "15.5k",
        credits: "15500",
        price_title: "$200",
        price: "200",
        wallet_address: '0xacf37878574c103b3e794f7c6fe5313e15691453' // @Misterfalido (Ozymandias)
    },
]

export const subscriptionPricing = [
    {
        subscription: "1",
        subscription_title: "1 Month",
        // img: "/sub_pricing1.png",
        // img: "/credit_pricing1.png",
        img: "/pricing_cards/c1.png"
    },
    {
        subscription: "3",
        subscription_title: "3 Months",
        img: "/pricing_cards/c3.png"
    },
    {
        subscription: "6",
        subscription_title: "6 Months",
        // img: "/sub_pricing2.png",
        // img: "/credit_pricing1.png",
        img: "/pricing_cards/c2.png"
    },
    // {
    //     subscription: "9",
    //     subscription_title: "9 Months",
    //     img: "/pricing_cards/c1.png"
    // },
    {
        subscription: "12",
        subscription_title: "12 Months",
        // img: "/sub_pricing3.png",
        // img: "/credit_pricing1.png",
        img: "/pricing_cards/c3.png"
    }
]

export const enableSubscriptionFor = [
    '0xf5674495165f69449cbe38fe30d455900288e5e1', // NFTommo
    '0xEbC502908df2d0AC5e84Cc20a0780bcb9456F8eC', // Babushka_Slayer
    // '0xebc502908df2d0ac5e84cc20a0780bcb9456f8ec', // Babushka_Slayer
]